<script setup>
import { commonValues } from '@/config/common';
import { WdsButtonLink, WdsIcon, WdsNavLink } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'div',
  },
  propertyTypeObj: {
    type: Object,
    required: true,
  },
  continentList: {
    type: Array,
    required: true,
  },
});

const { t } = useI18n();
const { useImage } = useImages();
const { useUrl } = useUrls();

const activeContinent = ref(props?.continentList?.[0]);

const getCommonValues = computed(() => {
  return commonValues;
});

function getImage(device) {
  const continentImage = activeContinent.value?.image?.[device];
  return continentImage ? useImage.getImage(continentImage) : null;
}

const getActiveContinentHeroImage = computed(() => {
  const continentImage = activeContinent.value?.image?.heroImage;
  return continentImage ? useImage.getImage(continentImage) : null;
});

const linkToContinentPage = computed(() => {
  return useUrl.getContinentPageUrl({
    propertyTypeSlug: props.propertyTypeObj.slug,
    urlFriendlyContinent: activeContinent.value?.urlFriendlyName,
  });
});

function toggleActiveContinent(selectedTab) {
  const newContinent = props.continentList.find((continent) => continent.name === selectedTab.tab);
  activeContinent.value = newContinent || {};
}

function getCountryLink(country) {
  return useUrl.getCountryPageUrl({
    propertyTypeSlug: props.propertyTypeObj.slug,
    urlFriendlyContinent: activeContinent.value?.urlFriendlyName,
    urlFriendlyCountry: country.urlFriendlyName,
  });
}

function viewAllPropertiesInLocationText(location) {
  return t('t_VIEWALLPROPERTIESINLOCATION', {
    PROPERTYTYPE: t(props.propertyTypeObj.transcode).toLowerCase(),
    LOCATION: location?.name,
  });
}
</script>

<template>
  <component
    :is="tag"
    class="countries-by-continent-section"
  >
    <h3
      class="countries-by-continent-heading"
      v-html="$t('t_PROPERTYTYPEWORLDWIDE', { PROPERTYTYPE: $t(propertyTypeObj.transcode) })"
    />
    <div
      class="continent-list"
      role="tablist"
    >
      <WdsNavLink
        v-for="continent in continentList"
        :key="continent.id"
        :name="continent.name"
        :label="continent.name"
        :aria-label="viewAllPropertiesInLocationText(continent)"
        :title="viewAllPropertiesInLocationText(continent)"
        :active="activeContinent.name === continent.name"
        @tab-clicked="toggleActiveContinent"
      />
    </div>
    <div
      class="continent-image-container"
      :style="`--bg-countries-by-continent-image-mobile: url(${getImage('mobile')}); --bg-countries-by-continent-image-tablet: url(${getImage('tablet')}); --bg-countries-by-continent-image-desktop: url(${getImage('desktop')});`"
    >
      <img
        v-if="getActiveContinentHeroImage"
        :src="getCommonValues.TRANSPARENTPIXEL"
        :alt="activeContinent.name"
        :title="activeContinent.name"
        width="1px"
        height="1px"
        loading="lazy"
      />
      <WdsIcon
        v-else
        asset="images"
      />
    </div>
    <ul
      v-for="continent in continentList"
      v-show="continent.id === activeContinent.id"
      :key="continent.id"
      class="continent-countries-list"
    >
      <li
        v-for="country in continent.countries"
        :key="country.id"
      >
        <a
          :href="getCountryLink(country)"
          :aria-label="viewAllPropertiesInLocationText(country)"
          :title="viewAllPropertiesInLocationText(country)"
        >{{ country.name }}</a>
      </li>
    </ul>
    <WdsButtonLink
      :href="linkToContinentPage"
      :text="viewAllPropertiesInLocationText(activeContinent)"
      :aria-label="viewAllPropertiesInLocationText(activeContinent)"
      :title="viewAllPropertiesInLocationText(activeContinent)"
      rel="noopener"
      icon-end="chevron-right"
      class="continent-view-all-properties"
    />
  </component>
</template>

<style scoped lang="scss">
.countries-by-continent-section {
  display: flex;
  flex-direction: column;

  .countries-by-continent-heading {
    @include title-2-bld;

    padding: 0 $wds-spacing-m;
    color: $wds-color-ink-darker;

    :deep(span) {
      color: $wds-color-purple;
    }
  }

  .continent-list {
    display: inline-flex;
    align-items: center;
    margin: 0 $wds-spacing-m $wds-spacing-l;
    overflow: auto hidden;
    position: relative;
    min-height: wds-rem(56px);

    button {
      white-space: nowrap;
    }
  }

  .continent-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $wds-color-ink-lighter;
    background-size: cover;
    background-position: center;
    fill: $wds-color-ink-light;
    width: 100%;
    height: wds-rem(256px);
    position: relative;
    margin-bottom: $wds-spacing-l;
    background-image: var(--bg-countries-by-continent-image-mobile);
    transition: background-image 0.5s ease-in-out;

    @include tablet {
      background-image: var(--bg-countries-by-continent-image-tablet);
    }

    @include desktop {
      height: wds-rem(379px);
      margin-bottom: $wds-spacing-xxxl;
      background-image: var(--bg-countries-by-continent-image-desktop);
    }

    :deep(svg) {
      width: $wds-spacing-xxl;
      height: $wds-spacing-xxl;
    }
  }

  .continent-countries-list {
    width: 100%;
    padding: 0 $wds-spacing-m;

    a {
      display: block;
      background-color: $wds-color-white;
      color: $wds-color-ink-darker;
      text-decoration: underline;

      @include link-1-reg;

      padding: wds-rem(12px) 0;

      &:hover,
      &:active,
      &:visited {
        color: $wds-color-ink;
      }

      &:focus-visible {
        border-radius: $wds-spacing-m;
        box-shadow: $border-focus-default;
      }
    }
  }

  .continent-view-all-properties {
    margin-top: $wds-spacing-m;
    cursor: pointer;
    align-self: flex-end;
  }

  @include tablet {
    padding: 0 $wds-spacing-l;

    .countries-by-continent-heading {
      @include title-1-bld;
    }

    .continent-countries-list {
      padding: 0;
    }
  }

  @include desktop {
    align-items: center;
    padding: 0;

    .countries-by-continent-heading {
      @include lg-title-3-bld;
    }

    .continent-list {
      overflow: visible;
      margin-bottom: $wds-spacing-xxxl;
    }

    .continent-countries-list {
      columns: 3;
      column-count: 3;
      column-gap: $wds-spacing-m;
      column-rule: none;
      padding: 0 $wds-spacing-l;

      a {
        padding: wds-rem(12px) $wds-spacing-m;
        margin-bottom: $wds-spacing-xs;
      }
    }

    .continent-view-all-properties {
      margin-top: $wds-spacing-xxxl;
      margin-right: $wds-spacing-m;
    }
  }
}
</style>
