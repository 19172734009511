<script setup>
import { SEGMENT_PAGE_DATA } from '@/config/analytics';
import { APPDOWNLOADBANNERLINKS } from '@/config/app-download-banner-links.js';

// ### MIDDLEWARE TO VALIDATE ROUTE
definePageMeta({ middleware: 'validate-worldwide' });

const { $tracking } = useNuxtApp();

const {
  t,
  locale,
} = useI18n();
const { useUrl } = useUrls();
const { buildSeo } = generateSeo();
const route = useRoute();
const { useRouteManager } = useRoutes();

// ### COMPOSABLES - APIs
const { getWorldwideData } = useApiWorldwide();
const { getAllContinents } = useApiContinents();
const { getWorldPageTags } = useApiSeo();
const { getWorldwideLocationTranslatedById } = useApiTranslator();
const {
  getTopCitiesWorldWide,
  getTopPropertiesWorldWide,
} = useApiTops();

// ### STORES
const sessionStore = useSessionStore();
const locationStore = useLocationStore();
const languageStore = useLanguageStore();

// ### VARIABLES INITIALIZATION
const gtmPageName = 'propertyLanding';
const gtmClassification = 'propertyLanding';
const segmentTracking = SEGMENT_PAGE_DATA.worldwide;
const urlParams = route?.params || null;
const urlQuery = route?.query || null;
const pageInfoData = {
  numberOfProperties: 0,
  numberOfCities: 0,
  canonicalUrl: '',
  ogImage: '',
};

// ### ROUTE VALIDATION
const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);
const urlData = useRouteManager.getUrlData(urlParams.propertyType);
useRouteManager.redirectRoute(urlData, urlQuery);

const propertyTypeKey = propertyTypeObj?.key;
const entryPageName = propertyTypeKey.toUpperCase();

// ### API
const worldwideData = await getWorldwideData(propertyTypeKey);
const continentsData = await getAllContinents(propertyTypeKey);
const seoData = await getWorldPageTags(propertyTypeKey);
const topCitiesWorldwide = await getTopCitiesWorldWide(propertyTypeKey);
const topPropertiesWorldwide = await getTopPropertiesWorldWide(propertyTypeKey);
const urlTranslations = await getWorldwideLocationTranslatedById('1');

const hrefUrls = useUrl.generateHrefLangForWorldWide(urlTranslations, propertyTypeKey);

if (worldwideData && worldwideData?.numberOfCities && worldwideData?.numberOfProperties) {
  pageInfoData.numberOfProperties = worldwideData.numberOfProperties;
  pageInfoData.numberOfCities = worldwideData.numberOfCities;
}

const imagesObj = {
  hostel: {
    mobile: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_s.jpg',
    tablet: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_m.jpg',
    desktop: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_l.jpg',
  },
  hotel: {
    mobile: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hotels_s.jpg',
    tablet: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hotels_m.jpg',
    desktop: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hotels_l.jpg',
  },
  guesthouse: {
    mobile: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/bandb_s.jpg',
    tablet: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/bandb_m.jpg',
    desktop: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/bandb_l.jpg',
  },
};
locationStore.setLocation('worldwide', { image: imagesObj[propertyTypeKey] });

pageInfoData.canonicalUrl = useUrl.getWorldPageUrl({ propertyTypeSlug: propertyTypeObj?.slug });
pageInfoData.ogImage = imagesObj?.[propertyTypeKey]?.desktop;

// ### SEO
const tradReplacements = {
  PROPERTYTYPE: t(propertyTypeObj?.transcode_s),
  PROPERTYTYPES: t(propertyTypeObj?.transcode),
  YEAR: new Date().getFullYear(),
  PROPCOUNT: pageInfoData?.numberOfProperties,
  CITYCOUNT: pageInfoData?.numberOfCities,
  NUMOFPROPERTIES: pageInfoData?.numberOfProperties,
  NUMOFCITIES: pageInfoData?.numberOfCities,
};

if (!seoData?.copy) {
  seoData.copy = t('t_RWDHOSTELWORLDBRINGSYOUHOSTELS');
  if (propertyTypeKey === 'hotel') {
    seoData.copy = t('t_RWDHOSTELWORLDBRINGSYOUHOTELS');
  } else if (propertyTypeKey === 'guesthouse') {
    seoData.copy = t('t_RWDHOSTELWORLDBRINGSYOUGUESTHOUSES');
  }
}

const seoMetaData = buildSeo.getMetaData(seoData, pageInfoData, tradReplacements);

const getMainTitle = computed(() => {
  if (seoMetaData?.h1) {
    return seoMetaData.h1;
  }

  return t('t_PROPERTYTYPEWORLDWIDE', { PROPERTYTYPE: t(propertyTypeObj?.transcode) });
});

const getPropertiesCountText = computed(() => {
  if (seoMetaData?.h2) {
    return seoMetaData.h2;
  }

  return t('t_RWDWEHAVEXPROPERTIESINXCITIES', {
    NUMOFPROPERTIES: pageInfoData.numberOfProperties,
    NUMOFCITIES: pageInfoData.numberOfCities,
    PROPERTYTYPE: pageInfoData.numberOfProperties === 1 ? t(propertyTypeObj.transcode_s) : t(propertyTypeObj.transcode),
  });
});

const getAppDownloadDetails = computed(() => {
  return {
    page: 'worldwide',
    linkPaths: APPDOWNLOADBANNERLINKS?.WORLDWIDE,
    linkParams: 'search',
  };
});

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  link: [
    {
      rel: 'canonical',
      href: seoMetaData?.canonicalUrl,
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_s.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_m.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_l.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hotels_s.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hotels_m.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hotels_l.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/bandb_s.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/bandb_m.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/bandb_l.jpg',
    },
  ].concat(hrefUrls),
}));

useSeoMeta({
  title: () => seoMetaData?.title,
  ogTitle: () => seoMetaData?.ogTitle,
  description: () => seoMetaData?.description,
  ogDescription: () => seoMetaData?.ogDescription,
  ogUrl: () => seoMetaData?.canonicalUrl,
  ogImage: () => seoMetaData?.ogImage,
  ogImageAlt: () => seoMetaData?.ogDescription,
  ogType: () => 'website',
  keywords: () => seoMetaData?.keywords,
  robots: () => 'index, follow, maxImagePreview:large',
  twitterCard: () => 'summary_large_image',
  twitterTitle: () => seoMetaData?.ogTitle,
  twitterDescription: () => seoMetaData?.ogDescription,
  twitterImage: () => seoMetaData?.ogImage,
  twitterImageAlt: () => seoMetaData?.ogDescription,
});

// ### TRACKING
sessionStore.setEntryPoint(entryPageName);

onMounted(() => {
  const trackingPropertyType = propertyTypeObj?.type?.toLowerCase();
  const trackingInfo = {
    gtm: {
      gtmPropertyType: trackingPropertyType,
      gtmCanonicalUrl: seoMetaData?.canonicalUrl,
      gtmPageName,
      gtmClassification,
    },
    segment: {
      event_name: segmentTracking.VIEWED,
      page_type: segmentTracking.TYPE,
      property_type: trackingPropertyType,
    },
  };

  $tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);
});
</script>

<script>
/* eslint-disable */
import { ca } from '@/lib/channelAttribution.js';

// @TODO: Need to get other property types
const entryPageNameJs = 'HOSTEL';

if (import.meta.client) {
  ca?.setChannelAttribution(null, entryPageNameJs);
}
/* eslint-enable */
</script>

<template>
  <LazyCommonHwAppDownloadBanner v-bind="getAppDownloadDetails" />

  <CommonHwHeader
    :free-cancellation-text="$t('t_CANCELFORFREEDESCRIPTION')"
    :headline="getMainTitle"
    :sub-label="getPropertiesCountText"
  />

  <CommonHwFreeCancellationPropertyTypeContainer
    class="hw-wrapper cancellation-and-property-type-section"
    :active-property-type="propertyTypeKey"
  />

  <WorldwideHwTopCities
    class="hw-wrapper ww-section"
    tag="section"
    :property-type-obj="propertyTypeObj"
    :city-list="topCitiesWorldwide"
  />

  <WorldwideHwTopProperties
    class="hw-wrapper ww-section"
    tag="section"
    :property-type="$t(propertyTypeObj.transcode)"
    :property-list="topPropertiesWorldwide"
  />

  <WorldwideHwCountriesByContinent
    class="hw-wrapper ww-section"
    tag="section"
    :property-type-obj="propertyTypeObj"
    :continent-list="continentsData"
  />

  <CommonHwPageTextContent
    v-if="seoMetaData.copy"
    class="hw-wrapper ww-section ww-about-section"
    :title="$t('t_ABOUTHOSTELWORLD')"
    title-position="center"
    :content="seoMetaData.copy"
  />

  <CommonHwLanguagePicker v-show="languageStore.isLanguagePickerOpen" />
</template>

<style lang="scss" scoped>
.ww-section {
  margin: wds-rem(72px) auto;

  @include desktop {
    margin: wds-rem(120px) auto;
  }
}

.headline-section {
  padding-bottom: $wds-spacing-m;

  @include desktop {
    display: flex;
    flex-direction: row;
    gap: $wds-spacing-m;
    align-items: center;

    :deep(.headline-main-title) {
      white-space: nowrap;
    }
  }
}

.cancellation-and-property-type-section {
  padding: 0;

  @include desktop {
    padding: 0 wds-rem(40px);
  }
}

.ww-about-section {
  :deep(.content) {
    ul > li h3 {
      @include body-1-reg;

      a {
        @include body-1-bld;
      }
    }
  }
}
</style>
